import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';
import SignInCard from './SignInCard';
import Content from './Content';
import TemplateFrame from '../SignUp/TemplateFrame';
import { styled, keyframes, Theme } from '@mui/material/styles';

// Create a styled div for the gradient background
const GradientBackground = styled(Stack)(({ theme }) => ({
  height: '100vh',  // Ensure it takes the full viewport height
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.mode === 'dark'
    ? 'linear-gradient(40deg, #000B2E, #0F2B4C)' // Dark theme gradient
    : 'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))', // Light theme gradient
  overflow: 'hidden', // Prevent scrolling
}));

const moveInCircle = keyframes`
  0% { transform: rotate(0deg); }
  50% { transform: rotate(180deg); }
  100% { transform: rotate(360deg); }
`;

const moveHorizontal = keyframes`
  0% { transform: translateX(-50%) translateY(-10%); }
  50% { transform: translateX(50%) translateY(10%); }
  100% { transform: translateX(-50%) translateY(-10%); }
`;

const StyledGradientsContainer = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  filter: 'blur(40px)',
});

const StyledGradientCircle = styled('div')(({ theme }: { theme: Theme }) => ({
  position: 'absolute',
  width: '80%',
  height: '80%',
  top: 'calc(50% - 40%)',
  left: 'calc(50% - 40%)',
  mixBlendMode: 'multiply',
  opacity: 0.7,
  ...(theme.palette.mode === 'dark' ? {
    mixBlendMode: 'hard-light',
  } : {}),
}));

const GradientCircle: React.FC<{ index: number }> = ({ index }) => {
  const animation = index === 1 ? moveInCircle : moveHorizontal;
  const background = index === 1 
    ? 'rgba(0, 71, 255, 0.15)' 
    : 'rgba(0, 150, 255, 0.15)';

  return (
    <StyledGradientCircle
      sx={{
        background: `radial-gradient(circle at center, ${background} 0%, rgba(0, 0, 0, 0) 50%)`,
        animation: `${animation} ${20 + index * 10}s ease infinite`,
      }}
    />
  );
};

const SignInSide: React.FC = () => {
  const [mode, setMode] = React.useState<'light' | 'dark'>('light');

  React.useEffect(() => {
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode as 'light' | 'dark');
    } else {
      const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setMode(systemPrefersDark ? 'dark' : 'light');
    }

    // Ensure no scrolling on the page
    document.body.style.overflow = 'hidden';
  }, []);

  const toggleColorMode = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    setMode(newMode);
    localStorage.setItem('themeMode', newMode);
  };

  return (
    <TemplateFrame mode={mode} toggleColorMode={toggleColorMode}>
      <CssBaseline enableColorScheme />
      <GradientBackground>
        <StyledGradientsContainer>
          <GradientCircle index={1} />
          <GradientCircle index={2} />
        </StyledGradientsContainer>
        <Stack
          direction="column"
          component="main"
          sx={{
            justifyContent: 'center',
            height: '100vh',  // Ensure it fills the entire viewport
            marginTop: 0,
            padding: 0,
            overflow: 'hidden',  // Prevent scroll
          }}
        >
          <Stack
            direction={{ xs: 'column-reverse', md: 'row' }}
            sx={{
              justifyContent: 'center',
              gap: { xs: 6, sm: 12 },
              p: 2,
              mx: 'auto',
              width: '100%',  // Ensure the width is contained
            }}
          >
            <Stack
              direction={{ xs: 'column-reverse', md: 'row' }}
              sx={{
                justifyContent: 'center',
                gap: { xs: 6, sm: 12 },
                p: { xs: 2, sm: 4 },
                m: 'auto',
                width: '100%',  // Ensure the width is contained
              }}
            >
            <Content mode={mode} /> {/* Pass mode prop here */}
            <SignInCard mode={mode} /> {/* Pass mode prop here */}
            </Stack>
          </Stack>
        </Stack>
      </GradientBackground>
    </TemplateFrame>
  );
};

export default SignInSide;
