import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import startblack from '../../media/starblack.png'; // Import the image
import startwhite from '../../media/starwhite.png'; // Import the image

interface ContentProps {
  mode: 'light' | 'dark'; // Define the type for mode
}

const items = [
  {
    icon: <SettingsSuggestRoundedIcon sx={{ color: 'text.secondary', fontSize: '1.5rem' }} />,
    title: 'Real-time Monitoring',
    description:
      'Monitor embedded devices in real-time, moving beyond outdated serial terminals.',
  },
  {
    icon: <ConstructionRoundedIcon sx={{ color: 'text.secondary', fontSize: '1.5rem' }} />,
    title: 'Robust Infrastructure',
    description:
      'A reliable platform that enhances product security and performance.',
  },
  {
    icon: <ThumbUpAltRoundedIcon sx={{ color: 'text.secondary', fontSize: '1.5rem' }} />,
    title: 'User-Friendly Dashboard',
    description:
      'An intuitive dashboard for easy data visualization and insights.',
  },
  // {
  //   icon: <AutoFixHighRoundedIcon sx={{ color: 'text.secondary' }} />,
  //   title: 'Streamlined Updates',
  //   description:
  //     'Quickly deploy updates and catch issues early to ensure smooth operation.',
  // },
];

export default function Content({ mode }: ContentProps) { // Use the defined props interface
  return (
    <Stack
      sx={{ flexDirection: 'column', alignSelf: 'center', gap: 4, maxWidth: 450 }}
    >
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <img 
          src={mode === 'light' ? startblack : startwhite} 
          alt="Logo" 
          style={{ width: '30%', height: 'auto' }} 
        />
      </Box>
      {items.map((item, index) => (
        <Stack key={index} direction="row" sx={{ gap: 2 }}>
          {item.icon}
          <div>
            <Typography gutterBottom sx={{ fontWeight: 'medium', fontSize: '1rem' }}>
              {item.title}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: '1rem' }}>
              {item.description}
            </Typography>
          </div>
        </Stack>
      ))}
    </Stack>
  );
}
