import React, { useState, useEffect, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { CustomLineChart } from './ChartLibrary'; // Adjust path as needed

interface SensorDataPoint {
  uptime: number;
  value: number;
  label: string;
  unit: string;
}

interface SensorData {
  [sensorId: string]: SensorDataPoint[];
}

interface WebSocketMessage {
  type: string;
  uptime: number;
  sensor_data: {
    sensor_id: string;
    value: number;
    label: string;
    unit: string;
  }[];
}

interface SessionsChartProps {}

const SessionsChart: React.FC<SessionsChartProps> = () => {
  const [sensorData, setSensorData] = useState<SensorData>({});
  const theme = useTheme();
  const points = 1000; // Maximum number of points to store for each sensor

  /**
   * Handles incoming WebSocket messages to update sensor data state.
   *
   * @param data - The incoming message data from the WebSocket.
   */
  const handleWebSocketMessage = useCallback((data: WebSocketMessage) => {
    if (data.type === 'full') {
      setSensorData((prevData) => {
        const updatedData = { ...prevData };

        // Update each sensor's data
        data.sensor_data.forEach((sensor) => {
          if (!updatedData[sensor.sensor_id]) {
            updatedData[sensor.sensor_id] = [];
          }

          // Append new data point
          updatedData[sensor.sensor_id].push({
            uptime: data.uptime,
            value: sensor.value,
            label: sensor.label,
            unit: sensor.unit,
          });

          // Maintain a maximum length of points
          if (updatedData[sensor.sensor_id].length > points) {
            updatedData[sensor.sensor_id].shift(); // Remove the oldest data point
          }
        });
        return updatedData;
      });
    }
  }, [points]);

  // Simulate WebSocket connection and mock data every few seconds
  useEffect(() => {
    const mockSensorData = [
      { sensor_id: 'sensor1', label: 'Temperature', unit: '°C' },
      { sensor_id: 'sensor2', label: 'Humidity', unit: '%' },
      { sensor_id: 'sensor3', label: 'Battery Level', unit: '%' },
      { sensor_id: 'sensor4', label: 'Pressure', unit: 'hPa' },
    ];

    let uptime = 0;

    const mockWebSocket = () => {
      const newData: WebSocketMessage = {
        type: 'full',
        uptime,
        sensor_data: mockSensorData.map((sensor) => ({
          sensor_id: sensor.sensor_id,
          label: sensor.label,
          unit: sensor.unit,
          value: Math.random() * 100, // Mock random sensor value
        })),
      };

      handleWebSocketMessage(newData);
      uptime += 1; // Increase uptime for each mock message
    };

    const interval = setInterval(mockWebSocket, 1000); // Mock data every second

    return () => {
      clearInterval(interval); // Cleanup interval on component unmount
    };
  }, [handleWebSocketMessage]);

  // Define a color palette for visualizing different sensors
  const colorPalette = [
    theme.palette.primary.light,
    theme.palette.primary.main,
    theme.palette.success.main,
    theme.palette.warning.main,
    theme.palette.error.main,
  ];

  return (
    <Card variant="outlined" sx={{ width: '100%' }}>
      <CardContent>
        <Typography component="h2" variant="subtitle2" gutterBottom>
          Sensor Data
        </Typography>
        <Grid container spacing={2}>
          {Object.entries(sensorData).map(([sensorId, data], index) => {
            const color = colorPalette[index] || theme.palette.grey[500];

            return (
              <Grid item xs={12} sm={6} md={6} key={sensorId}>
                <Stack spacing={2}>
                  <Stack
                    direction="row"
                    sx={{
                      alignContent: { xs: 'center', sm: 'flex-start' },
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <Typography variant="h4" component="p">
                      {data.length ? data[data.length - 1].value.toFixed(2) : '0.00'}
                    </Typography>
                    <Chip size="small" color="success" label={`${data[0]?.label} (${data[0]?.unit})`} />
                  </Stack>
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    Sensor readings for the last {data.length} data points
                  </Typography>
                  <CustomLineChart
                    sensorId={sensorId}
                    data={data}
                    color={color}
                  />
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SessionsChart;
