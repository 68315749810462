import * as React from 'react';
import { styled, alpha, keyframes } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Typography from '@mui/material/Typography';
import starwhite from '../media/starwhite.png';
import { useNavigate } from 'react-router-dom';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: 'blur(24px)',
  border: '1px solid',
  borderColor: theme.palette.divider,
  backgroundColor: alpha('#000000', 0.5),
  boxShadow: theme.shadows[1],
  padding: '8px 12px',
}));

const buttonGradient = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const GradientButton = styled(Button)({
  background: 'linear-gradient(-45deg, #0047FF, #0096FF, #0090b7)',
  backgroundSize: '200% 200%',
  animation: `${buttonGradient} 5s ease infinite`,
  border: 'none',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'inherit',
    filter: 'blur(6px)',
    opacity: 0,
    transition: 'opacity 0.2s ease',
    zIndex: -1,
  },
  '&:hover': {
    boxShadow: '0 0 20px rgba(0, 150, 255, 0.5)',
    '&::before': {
      opacity: 1,
    },
  },
});

const handleContactClick = () => {
  window.location.href = 'mailto:arturo.dlrios@orbbyt.com';
};

const scrollToSection = (elementId: string) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

export default function AppAppBar() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <AppBar
      position="fixed"
      sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', width: '95%', mt: 4 }}
    >
      <Box sx={{ width: '95%' }}>
        <StyledToolbar variant="dense" disableGutters>
          <Box 
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => scrollToSection('hero')}
          >
            <img src={starwhite} alt="Logo" style={{ height: '50px', marginRight: '-5px' }} />
            <Typography variant="h5" sx={{ color: 'white', fontWeight: 'bold' }}>
              Orbbyt
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center', gap: 9 }}>
            <Button 
              onClick={() => scrollToSection('hero')}
              sx={{ color: 'white', textTransform: 'none', fontSize: '1.1rem', mx: 1 }}
            >
              Home
            </Button>
            <Button 
              onClick={() => scrollToSection('bytetracer')}
              sx={{ color: 'white', textTransform: 'none', fontSize: '1.1em', mx: 1 }}
            >
              Explore
            </Button>
            <Button 
              onClick={handleContactClick}
              sx={{ color: 'white', textTransform: 'none', fontSize: '1.1rem', mx: 1 }}
            >
              Contact
            </Button>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 2,
              alignItems: 'center',
            }}
          >
           <Button 
              color="primary" 
              variant="text" 
              size="small" 
              sx={{ color: 'white', textTransform: 'none', fontSize: '1rem', mx: 1 }}
              onClick={() => navigate('/signin')} // Navigate to SignIn on button click
            >
            Sign in
            </Button>
            <GradientButton 
              size="small" 
              // onClick={() => navigate('/signup')}
              sx={{ 
                color: 'white', 
                textTransform: 'none', 
                fontSize: '1rem', 
                mx: 1,
                px: 2,
                py: 1,
              }}
            >
              Sign up
            </GradientButton>
          </Box>
          <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)} sx={{ color: 'white' }}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
              <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon sx={{ color: 'black' }} />
                  </IconButton>
                </Box>
                <Divider sx={{ my: 3 }} />
                <MenuItem onClick={() => { scrollToSection('hero'); toggleDrawer(false)(); }}>
                  Home
                </MenuItem>
                <MenuItem onClick={() => { scrollToSection('bytetracer'); toggleDrawer(false)(); }}>
                  Explore
                </MenuItem>
                <MenuItem onClick={() => { 
                  handleContactClick(); 
                  toggleDrawer(false)(); 
                }}>
                  Contact
                </MenuItem>
                <MenuItem>
                  <GradientButton 
                    fullWidth
                    // onClick={() => {
                    //   navigate('/signup');
                    //   toggleDrawer(false)();
                    // }}
                    sx={{ 
                      color: 'white',
                    }}
                  >
                    Sign up
                  </GradientButton>
                </MenuItem>
                <MenuItem>
                <Button 
                    color="primary" 
                    variant="outlined" 
                    fullWidth
                    onClick={() => {
                      navigate('/signin'); // Navigate to SignIn on button click
                      toggleDrawer(false)();
                    }}
                  >
                  Sign in
                  </Button>
                </MenuItem>
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Box>
    </AppBar>
  );
}
