import React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';

// Define types for props
interface AreaGradientProps {
  color: string;
  id: string;
}

/**
 * AreaGradient component defines a linear gradient used for filling the area in the chart.
 *
 * @param {Object} props - The component props.
 * @param {string} props.color - The color for the gradient.
 * @param {string} props.id - The unique identifier for the gradient.
 * @returns {JSX.Element} The rendered gradient definition.
 */
export const AreaGradient: React.FC<AreaGradientProps> = ({ color, id }) => (
  <defs>
    <linearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
      <stop offset="0%" stopColor={color} stopOpacity={0.5} />
      <stop offset="100%" stopColor={color} stopOpacity={0} />
    </linearGradient>
  </defs>
);

// Define types for CustomLineChart props
interface CustomLineChartProps {
  sensorId: string;
  data: { uptime: number; value: number; label: string; unit: string }[];
  color: string;
}

/**
 * CustomLineChart component renders a line chart for sensor data with area fill.
 *
 * @param {Object} props - The component props.
 * @param {string} props.sensorId - The unique identifier for the sensor.
 * @param {Array} props.data - The sensor data points to plot.
 * @param {string} props.color - The color for the chart series.
 * @returns {JSX.Element|null} The rendered chart or null if no data is available.
 */
export const CustomLineChart: React.FC<CustomLineChartProps> = ({ sensorId, data, color }) => {
  // Return null if there's no data to display
  if (data.length === 0) return null;

  // Limit data to the last 1000 entries for performance
  const limitedData = data.slice(-1000);
  
  // Prepare x-axis and y-axis data
  const xData = limitedData.map((_, index) => index); // Use index for x values
  const yData = limitedData.map(d => d.value);
  
  // Generate uptime labels in mm:ss format
  const uptimeLabels = limitedData.map(d => {
    const minutes = Math.floor(d.uptime / 60);
    const seconds = Math.floor(d.uptime % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`; // Format as mm:ss
  });

  // Set min and max for x-axis based on the indices
  const minX = 0;
  const maxX = limitedData.length - 1; // Maximum index for the x-axis

  return (
    <LineChart
      colors={[color]}
      xAxis={[
        {
          data: xData,
          scaleType: 'linear',
          valueFormatter: (value) => uptimeLabels[value] || '', // Format the x-axis labels
          label: 'Uptime (mm:ss)',
          min: minX,
          max: maxX,
          // Use ticks if necessary, but check if the library supports this.
          // Here we are removing the `ticks` property because it caused an issue.
        },
      ]}
      series={[
        {
          id: sensorId,
          showMark: false,
          curve: 'linear',
          area: true,
          data: yData,
        },
      ]}
      height={250}
      margin={{ left: 50, right: 20, top: 20, bottom: 30 }}
      grid={{ horizontal: true }}
      sx={{
        [`& .MuiAreaElement-series-${sensorId}`]: {
          fill: `url('#${sensorId}')`, // Apply the gradient fill
        },
      }}
    >
      <AreaGradient color={color} id={sensorId} />
    </LineChart>
  );
};
